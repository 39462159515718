import { useToast } from '@chakra-ui/react'
import { get } from 'lodash'
import { createContext, FC, ReactNode, useContext, useState } from 'react'
import { backendClient } from '../../network'

type AuthContextType = {
    isAuthenticated: boolean
    login: (input: LoginInput) => Promise<void>
    register: (input: RegisterInput) => Promise<void>
    logout: () => void
    isAuthenticating: boolean
}

type LoginInput = {
    username: string
    password: string
}

type RegisterInput = {
    email: string
    firstName: string
    lastName: string
    password: string
    phoneNumber: string
}

type User = {
    id: string
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    isEmailConfirmed: false
    isPhoneConfirmed: false
    isBlocked: false
}

const AuthContext = createContext<Partial<AuthContextType>>({})

export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isAuthenticating, setIsAuthenticating] = useState(false)
    const [user, setUser] = useState<User | null>(null)
    const toast = useToast()

    const login = async (input: LoginInput) => {
        try {
            setIsAuthenticating(true)
            const { data } = await backendClient.post('/user/login', input)
            setUser(data)
            setIsAuthenticating(false)
            setIsAuthenticated(true)
        } catch (error) {
            toast({
                description: get(error, 'message', 'Username/password invalid'),
                variant: 'solid',
                status: 'error',
            })
            setIsAuthenticating(false)
        }
    }

    const register = async (input: RegisterInput) => {
        try {
            setIsAuthenticating(true)
            const { data } = await backendClient.post('/user', input)
            setUser(data)
            setIsAuthenticating(false)
            setIsAuthenticated(true)
            toast({
                description: 'Your account has been created',
                variant: 'solid',
                status: 'success',
            })
        } catch (error) {
            setIsAuthenticating(false)
            toast({
                description: get(error, 'message', 'Username/password invalid'),
                variant: 'solid',
                status: 'error',
            })
        }
    }

    const logout = () => {
        setIsAuthenticated(false)
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, register, isAuthenticating }}>
            {children}
        </AuthContext.Provider>
    )
}
