import { Flex, Heading } from '@chakra-ui/react'
import { SideMenu } from 'common/components'
import { PageWrap } from 'layout'
import React from 'react'

const DashboardScreen: React.FC = () => {
    return (
        <PageWrap title="Dashboard Screen">
            <Flex p={5}>
                <SideMenu />
                <Flex ml={5}>
                    <Heading size="h2">Dashboard</Heading>
                </Flex>
            </Flex>
        </PageWrap>
    )
}

export default DashboardScreen
