import { Flex, Heading } from '@chakra-ui/react'
import { useAuthContext } from 'context'
import React from 'react'
import { FiHome, FiLogOut, FiUser } from 'react-icons/fi'

const ITEMS = [
    { path: '/', title: 'Home', icon: <FiHome /> },
    { path: '/profile', title: 'Profile', icon: <FiUser /> },
]

const SideMenu: React.FC = () => {
    const { logout } = useAuthContext()

    return (
        <Flex bg="#202433" p={5} height="95vh" borderRadius="2xl" flexDirection="column" shadow="2xl">
            <Heading marginBottom={10} color="whiteAlpha.800" size="h3">
                Locum Motion.
            </Heading>

            <Flex flexDirection="column" flex={1}>
                {ITEMS.map(({ title, path, icon }) => (
                    <Flex
                        key={path}
                        py={2}
                        px={4}
                        color="white"
                        borderRadius="2xl"
                        _hover={{ bg: 'whiteAlpha.200', cursor: 'pointer' }}
                        alignItems="center"
                    >
                        {icon}
                        <Heading marginLeft={5} size="h5" color="whiteAlpha.800">
                            {title}
                        </Heading>
                    </Flex>
                ))}
            </Flex>

            <Flex
                py={2}
                px={4}
                color="white"
                borderRadius="2xl"
                _hover={{ bg: 'whiteAlpha.200', cursor: 'pointer' }}
                alignItems="center"
                onClick={() => logout && logout()}
            >
                <FiLogOut />{' '}
                <Heading ml={5} size="h5">
                    Log out
                </Heading>
            </Flex>
        </Flex>
    )
}

export default SideMenu
