import { Heading, Text } from './chakraComponents'

export const theme = {
    colors: {
        text: '#000',
    },
    components: {
        Text,
        Heading,
    },
}
