import { ComponentStyleConfig } from '@chakra-ui/react'

export const Text: ComponentStyleConfig = {
    // Styles for the base style
    baseStyle: {
        lineHeight: '20px',
        color: 'text',
        fontWeight: 400,
    },
    // Styles for the size variations
    sizes: {
        small: {
            fontSize: '14px',
            lineHeight: '18px',
        },
        regular: {
            fontSize: '16px',
            lineHeight: '26px',
        },
        large: {
            fontSize: '18px',
            lineHeight: '28px',
        },
    },
    // Styles for the visual style variations
    variants: {},
    // The default `size` or `variant` values
    defaultProps: {
        size: 'regular',
    },
}
