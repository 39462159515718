import { Button, Flex, Heading, Input, Text } from '@chakra-ui/react'
import { useAuthContext } from 'context'
import { Field, Formik } from 'formik'
import { PageWrap } from 'layout'
import { ROUTE_PATHS } from 'navigation'
import React from 'react'
import { FcGoogle } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'

const LoginScreen: React.FC = () => {
    const { login, isAuthenticating } = useAuthContext()
    const navigate = useNavigate()

    const onLogin = (username: string, password: string) => {
        try {
            login && login({ username, password })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <PageWrap title="Login">
            <Flex width="100vw" overflow="hidden" minHeight="100vh" p={10} bg="#202433" justifyContent="center">
                <Flex flex={0.4} display={['none', 'none', 'none', 'flex']} justifyContent="center" alignItems="center">
                    <Heading color="white" size="h1">
                        Locum Motion.
                    </Heading>
                </Flex>
                <Flex flex={0.4} bg="#fff" borderRadius="2xl" p={10} flexDirection="column" maxWidth="500px">
                    <Heading size="h2">Login</Heading>
                    <Heading size="h5" fontWeight="500" mt={5}>
                        You can login with your registered account or quick login with your Google account.
                    </Heading>

                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        onSubmit={({ email, password }) => onLogin(email, password)}
                    >
                        {({ handleSubmit }) => (
                            <>
                                <Flex flexDirection="column" my={5}>
                                    <Button
                                        onClick={() => alert('Coming soon')}
                                        bg="#202433"
                                        variant="solid"
                                        color="white"
                                        leftIcon={<FcGoogle />}
                                        isLoading={isAuthenticating}
                                    >
                                        Login with Google
                                    </Button>
                                    <Flex alignItems="center" my={5}>
                                        <Flex height="2px" flex={0.5} bg="blackAlpha.500" />
                                        <Text px={5}>or</Text>
                                        <Flex height="2px" flex={0.5} bg="blackAlpha.500" />
                                    </Flex>
                                    <Field as={Input} name="email" mb={5} placeholder="email address*" />
                                    <Field as={Input} name="password" placeholder="password*" />
                                </Flex>
                                <Button onClick={() => handleSubmit()} isLoading={isAuthenticating}>
                                    Login
                                </Button>
                            </>
                        )}
                    </Formik>
                    <Flex mt={10}>
                        <Text color="#202433" mr={1} fontWeight="bold">
                            Don't have an account?
                        </Text>
                        <Text
                            color="blue.600"
                            fontWeight="bold"
                            cursor="pointer"
                            onClick={() => navigate(ROUTE_PATHS.REGISTER)}
                        >
                            Create one!
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </PageWrap>
    )
}

export default LoginScreen
