import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { theme } from 'common/theme/theme'
import { AuthProvider } from 'context'
import { Navigation } from 'navigation'

const App = () => {
    return (
        <AuthProvider>
            <ChakraProvider theme={extendTheme(theme)}>
                <Navigation />
            </ChakraProvider>
        </AuthProvider>
    )
}

export default App
