import DashboardScreen from 'containers/Dashboard'
import LoginScreen from 'containers/LoginScreen'
import RegisterScreen from 'containers/RegisterScreen'
import { useAuthContext } from 'context'
import React, { ReactElement } from 'react'
import { MemoryRouter, Route, Routes } from 'react-router-dom'

export const ROUTE_PATHS = {
    LOGIN: '/login',
    REGISTER: '/register',
    DASHBOARD: '/',
}

type RouteProps = {
    path: string
    element: ReactElement
}

const PUBLIC_ROUTES: RouteProps[] = [
    {
        path: ROUTE_PATHS.LOGIN,
        element: <LoginScreen />,
    },
    {
        path: ROUTE_PATHS.REGISTER,
        element: <RegisterScreen />,
    },
]

const PRIVATE_ROUTES: RouteProps[] = [
    {
        path: ROUTE_PATHS.DASHBOARD,
        element: <DashboardScreen />,
    },
]

const AuthenticatedNavigation = () => {
    return (
        <MemoryRouter initialEntries={[ROUTE_PATHS.LOGIN]}>
            <Routes>
                {PRIVATE_ROUTES.map(({ element, path }, index) => (
                    <Route key={index} path={path} element={element} />
                ))}
            </Routes>
        </MemoryRouter>
    )
}

const UnAuthenticatedNavigation = () => {
    return (
        <MemoryRouter initialEntries={[PUBLIC_ROUTES[0].path]}>
            <Routes>
                {PUBLIC_ROUTES.map(({ element, path }, index) => (
                    <Route key={index} path={path} element={element} />
                ))}
            </Routes>
        </MemoryRouter>
    )
}

export const Navigation: React.FC = () => {
    const { isAuthenticated } = useAuthContext()

    return isAuthenticated ? <AuthenticatedNavigation /> : <UnAuthenticatedNavigation />
}
