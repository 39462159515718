import { Box } from '@chakra-ui/react'
import { APP_NAME } from 'app_constants'
import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'

type PageWrapProps = {
    title: string
    children: ReactNode
}

export const PageWrap: React.FC<PageWrapProps> = ({ children, title }) => {
    return (
        <Box>
            <Helmet title={`${APP_NAME} | ${title}`} />
            {children}
        </Box>
    )
}
