import { ComponentStyleConfig } from '@chakra-ui/react'

export const Heading: ComponentStyleConfig = {
    // Styles for the base style
    baseStyle: {
        lineHeight: '20px',
        color: 'text',
    },
    // Styles for the size variations
    sizes: {
        h1: {
            fontSize: ['36px', '36px', '40px', '40px'],
            lineHeight: '60px',
        },
        h2: {
            fontSize: '32px',
            lineHeight: '48px',
        },
        h3: {
            fontSize: '22px',
            lineHeight: '30px',
        },
        h4: {
            fontSize: '18px',
            lineHeight: '26px',
        },
        h5: {
            fontSize: '14px',
            lineHeight: '18px',
        },
    },
    // Styles for the visual style variations
    variants: {},
    // The default `size` or `variant` values
    defaultProps: {
        size: 'h1',
    },
}
