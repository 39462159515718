import { Button, Flex, Heading, Input, Text } from '@chakra-ui/react'
import { useAuthContext } from 'context'
import { PageWrap } from 'layout'
import { ROUTE_PATHS } from 'navigation'
import React from 'react'
import { FcGoogle } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'

const RegisterScreen: React.FC = () => {
    const { register, isAuthenticating } = useAuthContext()
    const navigate = useNavigate()

    const onRegister = async () => {
        register && (await register({ firstName: '', lastName: '', email: '', password: '', phoneNumber: '' }))
    }

    return (
        <PageWrap title="Sign Up">
            <Flex width="100vw" overflow="hidden" minHeight="100vh" p={10} bg="#202433" justifyContent="center">
                <Flex flex={0.4} bg="#fff" borderRadius="2xl" p={10} flexDirection="column" maxWidth="500px">
                    <Heading size="h2">Register</Heading>
                    <Heading size="h5" fontWeight="500" mt={5}>
                        You can register your account by filling in the needed details or quick sign up with your Google
                        account.
                    </Heading>

                    <Flex flexDirection="column" my={5}>
                        <Button
                            onClick={() => onRegister()}
                            bg="#202433"
                            variant="solid"
                            color="white"
                            leftIcon={<FcGoogle />}
                            isLoading={isAuthenticating}
                        >
                            Sign up with Google
                        </Button>
                        <Flex alignItems="center" my={5}>
                            <Flex height="2px" flex={0.5} bg="blackAlpha.500" />
                            <Text px={5}>or</Text>
                            <Flex height="2px" flex={0.5} bg="blackAlpha.500" />
                        </Flex>
                        <Input mb={5} placeholder="first name*" />
                        <Input mb={5} placeholder="last name*" />
                        <Input mb={5} placeholder="email address*" />
                        <Input mb={5} placeholder="phone number*" />
                        <Input placeholder="password*" />
                    </Flex>
                    <Button onClick={() => onRegister()} isLoading={isAuthenticating}>
                        Sign Up
                    </Button>
                    <Flex mt={10}>
                        <Text color="#202433" mr={1} fontWeight="bold">
                            Have an account?
                        </Text>
                        <Text
                            color="blue.600"
                            fontWeight="bold"
                            cursor="pointer"
                            onClick={() => navigate(ROUTE_PATHS.LOGIN)}
                        >
                            Login.
                        </Text>
                    </Flex>
                </Flex>
                <Flex flex={0.4} display={['none', 'none', 'none', 'flex']} justifyContent="center" alignItems="center">
                    <Heading size="h1" color="white">
                        Locum Motion.
                    </Heading>
                    size='h1'
                </Flex>
            </Flex>
        </PageWrap>
    )
}

export default RegisterScreen
